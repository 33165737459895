@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixins';

.ProjectHeader {
    @include headerSize;
    opacity: 0;
    z-index: 100;
    transition: opacity 800ms ease-in-out;

    &-visible {
        opacity: 1;
    }

    border-bottom: 1px solid $black;

    display: flex;
    justify-content: space-between;
    align-items: center;

    color: $black;
    font-size: 15px;
    line-height: 17px;
    font-weight: 300;

    @include md {
        align-items: flex-start;
        & > span {
            height: auto;
            margin-top: 20px;
        }
    }

    @include xl {
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 16px;
    }

    &-title {
        text-align: left;
        @include sm {
            flex: 1 0 30%;
        }
    }

    &-counter {
        z-index: 100;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: $headerHeight-xs;
        display: flex;
        align-items: center;
        justify-content: center;
        @include sm {
            position: static;
            width: auto;
        }
        @include xl {
            height: $headerHeight-xl;
        }
    }

    &-get-lost {
        z-index: 500;

        position: absolute;
        bottom: 0;

        display: flex;
        justify-content: flex-end;
        align-items: center;
        color: $black;
        text-decoration: none;

        @include spacing(right, 1);
        width: 90px;
        height: $headerHeight-xs;
        @include sm {
            height: $headerHeight-sm;
        }
        @include md {
            margin-top: 0 !important;
            height: 35px !important;
        }
        @include lg {
            height: 35px !important;
        }
        @include xl {
            display: none;
        }
    }
}
