@import '../../assets/styles/variables';

@mixin landscape-covered-image {
    width: 100%;
    height: 100%;
    max-width: none;
    img {
        width: 100%;
        height: 100%;

    }
    &::after {
        content: none;
    }
}

@mixin portrait-covered-image {
    width: 100%;
    height: 100%;
    max-width: none;
    img {
        width: 100%;
        height: 100%;
    }
    &::after {
        content: none;
    }
}

%portrait {
    position: relative;
    overflow: hidden;
    &::after {
        content: '';
        display: block;
        padding-bottom: #{100%/4 * 5};
    }
}

%landscape {
    position: relative;
    overflow: hidden;
    &::after {
        content: '';
        display: block;
        padding-bottom: #{100%/5 * 4};
    }
}

%image {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
    opacity: 1;
    -webkit-transition: opacity 300ms ease;
    -moz-transition: opacity 300ms ease;
    -ms-transition: opacity 300ms ease;
    -o-transition: opacity 300ms ease;
    transition: opacity 300ms ease;
}
