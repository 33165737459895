@import '../../assets/styles/mixins';

@mixin pagePaddings() {
    margin: 0;
    @include spacing(padding-left, 4);
    @include spacing(padding-right, 4);
    @include sm {
        padding-left: 16px;
        padding-right: 16px;
    }
    @include md {
        @include spacing(padding-left, 4);
        @include spacing(padding-right, 4);
    }
    @include lg {
        padding-left: 26px;
        padding-right: 26px;
    }
    @include lg {
        padding-left: 34px;
        padding-right: 34px;
    }
}

.Blocks {
    background-color: $blue;
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 24px;
    @include xl {
        font-size: 18px;
        line-height: 22px;
    }
}

.Block {
    width: 100%;
    &:not(:first-child) {
        border-top: 1px solid $black;
    }

    &Header {
        @include pagePaddings;
        position: sticky;
        top: 0;

        height: 36px;
        flex: 1 0 100%;

        display: flex;
        align-items: center;
        justify-content: space-between;

        cursor: pointer;

        font-family: 'BasierSquareRegular';
        font-size: 12px;
        line-height: 15px;
        font-weight: 400;
        text-transform: uppercase;

        background-color: $white;

        a {
            outline: none;
            text-decoration: none;
            color: $black;
        }

        &--colored {
            background-color: $blue;
        }

        &--linked {
            cursor: pointer !important;
        }

        @include sm {
            cursor: inherit;
        }
    }

    &Content {
        height: calc(100% - 36px);
        overflow-y: auto;

        word-break: break-word;
        white-space: pre-wrap;

        & > span {
            @include pagePaddings;
            padding-bottom: 23px;
            display: inline-block;
        }

        a {
            color: $black;
            text-decoration: none;
            border-bottom: 1px solid $black;
            padding-bottom: 1px;
        }
    }
}
