@import 'normalize';
@import 'variables';
@import 'fonts';

html,body {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

html {
    height: 100%;
    overflow: hidden;

    font-size: 16px;
    font-family: Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
}

body {
    height: 100%;
    margin: 0;
    overflow: hidden;
    font-family: 'Lausanne-300';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: $black;
    background-color: $white;
}

/////// PAGE TRANSITIONS

.fade-appear {
    opacity: 0;

    &-appear-active {
        opacity: 1;
        transition: opacity 500ms ease-in-out;
    }

    &-appear-done {
        opacity: 1;
    }
}

/////// TRANSITIONS FOR ARCHIVE

.accordion {
    &-appear {
        opacity: 0;
        &-active {
            opacity: 1;
            transition: opacity 500ms ease-in-out;
        }
        &-done {
            opacity: 1;
        }
    }

    &-enter {
        opacity: 0;

        &-active {
            opacity: 1;
            transition: opacity 500ms ease-in-out;
        }
        &-done {
            opacity: 1;
        }
    }

    &-exit {
        opacity: 1;
        &-active {
            opacity: 0;
            transition: opacity 500ms ease-in-out;
        }
        &-done {
            display: none;
            opacity: 0;
        }
    }
}

.search-results {
    display: none;
    opacity: 0;

    &-enter {
        display: block;
        opacity: 0;

        &-active {
            opacity: 1;
            transition: opacity 500ms ease-in-out;
        }
        &-done {
            display: block;
            opacity: 1;
        }
    }
}
