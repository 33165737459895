.Parallax {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.ParallaxCard {
    position: absolute;
    will-change: transform;
    transition: all 250ms ease;

    &-inner {
        opacity: 1;

        -webkit-transition: opacity 1000ms ease;
        -moz-transition: opacity 1000ms ease;
        -ms-transition: opacity 1000ms ease;
        -o-transition: opacity 1000ms ease;
        transition: opacity 1000ms ease;
    }

    &-portrait {
        &::after {
            content: '';
            display: block;
            padding-bottom: #{100%/4 * 5};
        }
    }

    &-landscape {
        &::after {
            content: '';
            display: block;
            padding-bottom: #{100%/5 * 4};
        }
    }

    &-transparent {
        opacity: 0;
    }
}
