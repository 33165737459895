@import '../../assets/styles/variables';

.Accordion {
    width: 100%;

    &Section {
        border-top: 1px solid $black;
        display: flex;
        flex-wrap: wrap;
        overflow: hidden;
        height: 36px;
        transition: height 600ms ease;

        &--active {
            height: calc(100% - #{$accordionHeaderHeight});
            overflow-y: auto;
        }
    }

    &--disabled {
        overflow-y: auto;
    }
    &--disabled > &Section {
        height: auto;
    }

    &--horizontal {
        display: flex;
        flex-direction: row;
    }
    &--horizontal > &Section {
        flex: 1;
        &:first-child {
            border-right: 1px solid $black;
        }
    }
}
