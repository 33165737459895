@import '../../assets/styles/mixins';

.Cards {
    opacity: 1;
    background-color: $home-bg;
    &--exit {
        opacity: 0;
        transition: opacity 500ms ease-in-out;
    }
}

.Card {
    z-index: 1;
    -webkit-transition: z-index, width 200ms ease;
    -moz-transition: z-index, width 200ms ease;
    -ms-transition: z-index, width 200ms ease;
    -o-transition: z-index, width 200ms ease;
    transition: z-index, width 200ms ease;
    &:hover {
        z-index: 100;
    }
}

.CardImage {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;

    background-color: $gallery-bg;
    color: $gallery-bg;
}
