@import '../../../assets/styles/mixins';
@import '../../Image/mixins';

.ProjectModule {
    display: flex;
    justify-content: center;

    height: calc(100% - #{$headerHeight-xs});
    @include sm {
        height: 100%;
    }

    &--getLost {
        cursor: none;

        @include sm {
            height: calc(100% - #{$headerHeight-sm});
        }
        @include md {
            height: calc(100% - 35px);
        }
        @include lg {
            height: calc(100% - 35px);
        }
        @include xl {
            height: 100%;
        }

        &--effects {
            img {
                background-color: $gallery-bg;
                color: $gallery-bg;
                &:hover {
                    opacity: 0.8;
                }
            }
        }
    }

    &Cursor {
        display: none;
        opacity: 0;
        z-index: 75;
        position: fixed;
        top: 0;
        left: 0;
        color: $black;
        font-size: 18px;
    }

    &--getLost:hover &Cursor {
        display: block;
        opacity: 1;
        transition: opacity 1000ms ease;
        @media (hover: none) {
            display: none;
        }
    }

    h2 {
        text-align: left;
        position: absolute;
        right: 0;
        font-weight: 200;
        font-size: 12px;
        padding: 10px;
        z-index: 1;
    }
}
