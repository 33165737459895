@import '../../assets/styles/mixins';

.About {
    @include spacedForHeader;

    & > section:first-child {
        @include spacing(flex-basis, 40);
        @include sm {
            @include spacing(flex-basis, 27);
        }
        @include lg {
            @include spacing(flex-basis, 20);
        }
    }

    & > section:last-child {
        @include spacing(flex-basis, 40);
        @include sm {
            @include spacing(flex-basis, 53);
        }
        @include lg {
            @include spacing(flex-basis, 60);
        }
    }
}
