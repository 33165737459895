@import '../../assets/styles/variables';

.LoopScroll {
    overflow: hidden;
    position: relative;
    outline: none;

    &-el {
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;

        &-wrapper {
            width: 100%;
            height: 100%;
            -webkit-transition: opacity 200ms ease-in-out, background-color 1000ms ease;
            -moz-transition: opacity 200ms ease-in-out, background-color 1000ms ease;
            -ms-transition: opacity 200ms ease-in-out, background-color 1000ms ease;
            -o-transition: opacity 200ms ease-in-out, background-color 1000ms ease;
            transition: opacity 200ms ease-in-out, background-color 1000ms ease;
        }
    }
}

.Next {
    position: absolute;
    width: 100%;
    height: 10%;
    z-index: 100;
    cursor: url(../../assets/img/arrow-up.svg), auto;
}

.Prev {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 10%;
    z-index: 100;
    cursor: url(../../assets/img/arrow-down.svg), auto;
}