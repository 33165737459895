.Project {
    height: 100%;

    &-bg1,
    &-bg1 img[alt]:after {
        background-color: #ededda;
    }

    &-bg2,
    &-bg2 img[alt]:after {
        background-color: #ebd9d9;
    }

    &-bg3,
    &-bg3 img[alt]:after {
        background-color: #d3ecec;
    }

    &-bg4,
    &-bg4 img[alt]:after {
        background-color: #e1d6ec;
    }
}
