@font-face {
    font-family: 'Lausanne-300';
    src: url('../fonts/Lausanne-300.eot');
    src: url('../fonts/Lausanne-300.eot') format('embedded-opentype'),
        url('../fonts/Lausanne-300.woff2') format('woff2'), url('../fonts/Lausanne-300.woff') format('woff'),
        url('../fonts/Lausanne-300.ttf') format('truetype'), url('../fonts/Lausanne-300.svg#Lausanne-300') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Lausanne-300Italic';
    src: url('../fonts/Lausanne-300Italic.eot');
    src: url('../fonts/Lausanne-300Italic.eot') format('embedded-opentype'),
        url('../fonts/Lausanne-300Italic.woff2') format('woff2'), url('../fonts/Lausanne-300Italic.woff') format('woff'),
        url('../fonts/Lausanne-300Italic.ttf') format('truetype'),
        url('../fonts/Lausanne-300Italic.svg#Lausanne-Italic') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'BasierSquareRegular';
    src: url('../fonts/Basier-Square-Regular.otf');
    font-weight: normal;
    font-style: normal;
}
