@import '../../assets/styles/mixins';
@import '../Image/mixins';

.Search {
    width: 100%;

    &--fullscreen {
        max-height: 100%;
        overflow-y: auto;
    }

    &Header {
        position: sticky;
        top: 0;
        background-color: $white;
        z-index: 100;

        flex: 1 0 100%;
        height: 36px;
        margin: 0;

        cursor: pointer;

        font-family: 'BasierSquareRegular';
        font-size: 12px;
        line-height: 15px;
        font-weight: 400;
        text-transform: uppercase;

        display: flex;
        align-items: center;
        justify-content: center;

        @include sm {
            position: static;
            @include spacing(padding-left, 4);
            justify-content: flex-start;
        }
        @include md {
            position: sticky;
            @include spacing(padding-left, 0);
            justify-content: center;
        }
        @include lg {
            position: static;
            @include spacing(padding-left, 4);
            justify-content: flex-start;
        }
        @include xl {
            font-size: 10px;
            line-height: 12px;
        }
    }

    &Results {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: flex-start;
        align-content: flex-start;

        padding-top: $accordionHeaderHeight;
        .Search--fullscreen & {
            padding-top: $accordionHeaderHeight + 35;
        }

        @include spacing(padding-left, 2);
        @include spacing(padding-right, 2);

        .Search:not(.Search--fullscreen) & {
            @include sm {
                @include spacing(padding-left, 4);
                @include spacing(padding-right, 4);
            }
            @include lg {
                @include spacing(padding-left, 4);
                @include spacing(padding-right, 4);
            }
        }
    }
    &NoResults {
        display: block;
        margin: auto;
        text-align: center;

        font-style: normal;
        font-weight: 300;
        font-size: 20px;
        line-height: 24px;
        @include xl {
            font-size: 18px;
            line-height: 22px;
        }
    }
}

.ImageResult {
    cursor: pointer;
    display: block;
    position: relative;

    color: $black;
    text-decoration: none;

    &--portrait {
        @extend %portrait;
    }

    &--landscape {
        @extend %landscape;
    }

    img {
        @extend %image;
    }

    &:before {
        background-color: $white;
        content: ' ';
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        border: 1px solid $black;
    }

    // sizes for XS
    margin-bottom: 12px;
    @include spacingNew(width, 76, 4, 1);

    // sizes for SM
    @include sm {
        &--landscape {
            @include spacingNew(width, 36, 8, 2);
            .Search--fullscreen & {
                margin-right: 12px;
                @include spacingNew(width, 36, 4, 1);
            }
        }
        &--portrait {
            @include spacingNew(width, 23, 8, 2);
            .Search--fullscreen & {
                margin-right: 12px;
                @include spacingNew(width, 23, 4, 1);
            }
        }
    }

    // sizes for MD
    $mdPortraitHeight: 5 * 15 / 4;
    $mdLandscapeWidth: $mdPortraitHeight * 5 / 4;
    @include md {
        margin-right: 16px;
        margin-bottom: 16px;

        &--landscape {
            @include spacingNew(width, $mdLandscapeWidth, 4, 1);
        }
        &--portrait {
            @include spacingNew(width, 15, 4, 1);
        }
    }

    // sizes for LG
    $lgPortraitHeight: 5 * 11 / 4;
    $lgLandscapeWidth: $lgPortraitHeight * 5 / 4;
    @include lg {
        margin-right: 12px;
        margin-bottom: 12px;

        &--landscape {
            @include spacingNew(width, $lgLandscapeWidth, 8, 2);
            .Search--fullscreen & {
                @include spacingNew(width, $lgLandscapeWidth, 4, 1);
            }
        }
        &--portrait {
            @include spacingNew(width, 11, 8, 2);
            .Search--fullscreen & {
                @include spacingNew(width, 11, 4, 1);
            }
        }
    }

    // sizes for XL
    $xlPortraitHeight: 5 * 6 / 4;
    $xlLandscapeWidth: $xlPortraitHeight * 5 / 4;
    @include xl {
        &--landscape {
            @include spacingNew(width, $xlLandscapeWidth, 8, 2);
            .Search--fullscreen & {
                @include spacingNew(width, $xlLandscapeWidth, 4, 1);
            }
        }
        &--portrait {
            @include spacingNew(width, 6, 8, 2);
            .Search--fullscreen & {
                @include spacingNew(width, 6, 4, 1);
            }
        }
    }

    :global(.search-image-hovered) &:not(:hover) {
        filter: grayscale(100%);
        transition: all 300ms ease-in-out;
    }
}

.SearchInput {
    z-index: 110;

    position: absolute;
    top: 0;
    left: 30%;
    right: 30%;
    width: 40%;
    height: #{$headerHeight-xs};
    padding: 0;
    overflow: hidden;

    &:focus {
        outline: none;
    }

    align-items: center;
    display: flex;
    justify-content: center;

    background-color: transparent;
    color: $black;

    border: none;
    border-radius: 0;

    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    line-height: 17px;

    text-align: center;

    @include md {
        top: 12px;
    }

    @include xl {
        top: 10px;
        font-size: 14px;
        line-height: 16px;
    }
}

.LoadMore {
    width: 100%;
    text-align: center;
    margin: 2px 0 14px;

    cursor: pointer;
    font-family: 'BasierSquareRegular';
    font-size: 12px;
    line-height: 15px;

    @include md {
        margin-top: 0;
        margin-bottom: 45px;
    }
    @include lg {
        margin-top: 0;
        margin-bottom: 10px;
    }
    @include xl {
        font-size: 10px;
        line-height: 12px;
    }
}
