@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';

.CloseButton {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    a,
    button {
        cursor: pointer;
        display: block;
        z-index: 100;
        width: 26px;
        height: $headerHeight-xs;

        background-image: url(../../assets/img/close-xs.svg);
        background-repeat: no-repeat;
        background-position: center;
        background-color: transparent;
        border: none;



        @include xl {
            width: 28px;
            background-image: url(../../assets/img/close-xl.svg);
        }
    }

    @include sm {
        flex: 1 0 30%;
        background-position: right;
    }

    @include md {
        margin-top: 10px !important;
    }
}
