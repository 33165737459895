@import '../../assets/styles/mixins';

$backToPathHeight-xs: 28px;
$backToPathHeight-md: 36px;

.SubSequence {
    position: relative;

    -webkit-transition: background-color 1000ms ease;
    -moz-transition: background-color 1000ms ease;
    -ms-transition: background-color 1000ms ease;
    -o-transition: background-color 1000ms ease;
    transition: background-color 1000ms ease;

    &-bg1,
    &-bg1 img[alt]:after {
        background-color: #ededda;
    }

    &-bg2,
    &-bg2 img[alt]:after {
        background-color: #ebd9d9;
    }

    &-bg3,
    &-bg3 img[alt]:after {
        background-color: #d3ecec;
    }

    &-bg4,
    &-bg4 img[alt]:after {
        background-color: #e1d6ec;
    }

    &-closeButton {
        position: fixed;
        @include spacingNew(right, 2);
        @include md {
            @include spacingNew(right, 1);
        }
    }

    &-module {
        position: absolute;
        top: 0;
        width: 100%;

        margin-top: $headerHeight-xs;
        height: calc(100% - #{$headerHeight-xs});
        @include sm {
            margin-top: $headerHeight-sm;
            height: calc(100% - #{$headerHeight-sm});
        }
        @include md {
            margin-top: $headerHeight-md;
            height: calc(100% - #{$headerHeight-md});
        }
        @include lg {
            margin-top: $headerHeight-lg;
            height: calc(100% - #{$headerHeight-lg});
        }
        @include xl {
            margin-top: $headerHeight-xl;
            height: calc(100% - #{$headerHeight-xl});
        }
    }

    &-backToPath {
        display: block;
        cursor: none;
        height: calc(100% - #{$backToPathHeight-xs});
        @include md {
            height: calc(100% - #{$backToPathHeight-md});
        }
        @include xl {
            height: 100%;
        }
    }

    &-backToPath-footer {
        color: $black;
        text-decoration: none;
        position: absolute;
        width: 123px;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        @include spacingNew(right, 1);

        height: $backToPathHeight-xs;
        @include md {
            height: $backToPathHeight-md;
        }
        @include xl {
            display: none;
        }
    }

    &-backToPath-cursor {
        display: none;
        z-index: 100;
        position: fixed;
        top: 0;
        left: 0;
        color: $black;
        font-size: 18px;
    }

    &-backToPath:hover &-backToPath-cursor {
        display: block;
        opacity: 1;
        transition: opacity 1000ms ease;
        @media (hover: none) {
            display: none;
        }
    }
}
