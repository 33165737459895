@import '../../assets/styles/mixins';
@import './mixins';

@mixin positioning($size) {
    &-#{$size}-middle {
        margin-right: auto;
        margin-left: auto;
    }
    &-#{$size}-top {
        margin-top: 0;
        margin-bottom: auto;
    }
    &-#{$size}-bottom {
        margin-top: auto;
        margin-bottom: 0;
    }
    &-#{$size}-left {
        margin-right: auto;
        margin-left: 0;
    }
    &-#{$size}-right {
        margin-right: 0;
        margin-left: auto;
    }
    &-#{$size}-center {
        margin-right: auto;
        margin-left: auto;
    }
}

.ProjectImage {
    margin: auto;

    &:before {
        background-color: transparent;
        content: ' ';
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        border: 1px solid $black;
    }

    img[alt]:after {
        display: block;
        position: absolute;
        top: 1px;
        left: 1px;
        width: calc(100% - 2px);
        height: calc(100% - 2px);
        content: '';
    }

    &-portrait {
        @extend %portrait;
    }

    &-landscape {
        @extend %landscape;
    }

    img {
        @extend %image;
    }

    @media screen and (max-width: #{$sm - 1}) {
        @include positioning('xs');
    }
    @media screen and (min-width: $sm) and (max-width: #{$md - 1}) {
        @include positioning('sm');
    }
    @media screen and (min-width: $md) and (max-width: #{$lg - 1}) {
        @include positioning('md');
    }
    @media screen and (min-width: $lg) and (max-width: #{$xl - 1}) {
        @include positioning('lg');
    }
    @include xl {
        @include positioning('xl');
    }
}

.ImageSize {
    // PORTRAIT
    &-xl-portrait {
        @media screen and (max-width: #{$md - 1}) {
            @include portrait-covered-image;
        }
        @include md {
            @include spacing(width, 72);
        }
        @include xl {
            @include portrait-covered-image;
        }
    }

    &-lg-portrait {
        @include spacing(width, 76);
        @include sm {
            @include spacing(width, 30);
        }
        @include md {
            @include spacing(width, 72);
        }
        @include lg {
            @include spacing(width, 38);
        }
        @include xl {
            @include spacing(width, 30);
        }
    }

    &-md-portrait {
        @include spacing(width, 56);
        @include sm {
            @include spacing(width, 24);
        }
        @include md {
            @include spacing(width, 48);
        }
        @include lg {
            @include spacing(width, 32);
        }
        @include xl {
            @include spacing(width, 24);
        }
    }

    &-sm-portrait {
        @include spacing(width, 40);
        @include sm {
            @include spacing(width, 20);
        }
        @include md {
            @include spacing(width, 32);
        }
        @include lg {
            @include spacing(width, 24);
        }
        @include xl {
            @include spacing(width, 16);
        }
    }

    // LANDSCAPE

    &-xl-landscape {
        @include spacing(width, 76);
        @media screen and (min-width: $sm) and (max-width: #{$md - 1}) {
            @include landscape-covered-image;
        }
        @include md {
            @include spacing(width, 72);
        }
        @include lg {
            @include landscape-covered-image;
        }
    }

    &-lg-landscape {
        @include spacing(width, 76);
        @include sm {
            @include spacing(width, 46);
        }
        @include md {
            @include spacing(width, 72);
        }
        @include lg {
            @include spacing(width, 62);
        }
        @include xl {
            @include spacing(width, 46);
        }
    }

    &-md-landscape {
        @include spacing(width, 68);
        @include sm {
            @include spacing(width, 36);
        }
        @include md {
            @include spacing(width, 56);
        }
        @include lg {
            @include spacing(width, 48);
        }
        @include xl {
            @include spacing(width, 34);
        }
    }

    &-sm-landscape {
        @include spacing(width, 56);
        @include sm {
            @include spacing(width, 30);
        }
        @include md {
            @include spacing(width, 32);
        }
        @include lg {
            @include spacing(width, 32);
        }
        @include xl {
            @include spacing(width, 22);
        }
    }
}
