@import '../../assets/styles/mixins';
@import '../Image/mixins.scss';

.Gallery {
    header {
        justify-content: space-between;
        display: flex;
        @include headerSize;
    }

    &Spacer {
        width: 25px;
        visibility: hidden;
    }

    &Tags {
        display: inline-block;
        width: calc(80%);
        overflow: hidden;

        color: $black;
        text-overflow: ellipsis;
        text-align: center;
        white-space: nowrap;

        margin: auto;
        font-size: 15px;
        line-height: 17px;

        span {
            cursor: pointer;
            color: $black;
            text-align: center;
            white-space: nowrap;

            margin: auto;
            font-size: 15px;
            line-height: 17px;

            text-decoration: none;
            &::after {
                content: ', ';
            }
            &:last-child::after {
                content: '';
            }
        }

        @include xl {
            font-size: 14px;
            line-height: 16px;
        }
    }

    &Close {
        width: 25px;
        flex: none !important;
        margin-top: 0 !important;
    }

    &Swiper {
        height: calc(100% - #{$headerHeight-xs * 2});
        padding-bottom: $headerHeight-xs;
        @include sm {
            height: calc(100% - #{$headerHeight-xs});
            padding-bottom: 0;
            align-items: flex-end;
        }

        @include md {
            height: calc(100% - #{$headerHeight-md});
        }

        @include xl {
            height: calc(100% - #{$headerHeight-xl});
        }

        &Next,
        &Prev {
            position: absolute;
            top: 0;
            width: 50%;
            height: 100%;
            cursor: pointer;
        }

        &Next {
            right: 0;
        }

        &Prev {
            left: 0;
        }

        :global(.swiper-button-prev) {
            @extend .SwiperButton;
            left: 0;
            z-index: 0 !important;
        }
        :global(.swiper-button-next) {
            @extend .SwiperButton;
            right: 0;
            transform: scaleX(-1);
            z-index: 0 !important;
        }
        :global(.swiper-button-disabled) {
            visibility: hidden;
        }
    }

    &Slide {
        display: flex;
        align-items: center;
        @include sm {
            align-items: flex-end;
        }
    }

    &SlideImage {
        max-height: 100%;
        margin-left: auto;
        margin-right: auto;

        z-index: 1000;

        @include spacingNew(width, 76);

        @include sm {
            @include spacingNew(width, 30);
        }
        @include md {
            @include spacingNew(width, 72);
        }
        @include lg {
            @include spacingNew(width, 38);
        }
        @include xl {
            @include spacingNew(width, 32);
        }

        &-portrait {
            @extend %portrait;
        }

        &-landscape {
            @extend %landscape;
            @include sm {
                $portraitHeightSm: 30 / 4 * 5;
                // same height as height of portrait
                @include spacingNew(width, $portraitHeightSm / 4 * 5);
            }
            @include md {
                align-self: center;
                @include spacingNew(width, 72);
            }
            @include lg {
                $portraitHeightLg: 38 / 4 * 5;
                // same height as height of portrait
                align-self: flex-end;
                @include spacingNew(width, $portraitHeightLg / 4 * 5);
            }
            @include xl {
                // same height as height of portrait
                $portraitHeightXl: 32 / 4 * 5;
                @include spacingNew(width, $portraitHeightXl / 4 * 5);
            }
        }

        &::before {
            content: ' ';
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            border: 1px solid $black;
        }

        img {
            @extend %image;
            color: transparent;
            &[src=''] {
                display: none;
            }
        }
    }
}

.SwiperButton {
    z-index: 1000;
    cursor: pointer;
    position: absolute;
    bottom: 0;
    @include spacingNew(width, 12);
    height: $headerHeight-xs;
    @include spacingNew(margin-right, 2);
    @include spacingNew(margin-left, 2);
    background-image: url(../../assets/img/gallery-arrow.svg);
    background-position-y: center;
    background-repeat: no-repeat;
    overflow: hidden;
    @include sm {
        height: 100%;
        background-position: left center;
        @include spacingNew(margin-right, 1);
        @include spacingNew(margin-left, 1);
        @include spacingNew(width, 23);
    }
    @include md {
        @include spacingNew(width, 3);
    }
    @include lg {
        @include spacingNew(width, 20);
    }
    @include xl {
        @include spacingNew(width, 23);
    }
}
