@import '../../assets/styles/mixins';

.Archive {
    @include spacedForHeader;
}

.ArchiveContent {
    height: 100%;
}

.ArchiveGallery {
    display: none;
    &--open {
        display: block;

        z-index: 500;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        background-color: $gallery-bg;
    }
}
