@import '../../assets/styles/mixins';

.ContactAccordion {
    width: 100%;
    min-height: 100%;
    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    font-style: normal;
    font-weight: 300;

    @include sm {
        position: static;
        height: 100%;
        min-height: auto;
    }

    @include md {
        min-height: auto;
        position: relative;
    }

    @include lg {
        position: static;
    }

    section:first-child {
        border-top: 0;
    }

    &,
    a {
        color: $black;
        text-decoration: none;
        font-style: normal;
        font-weight: 300;
        font-size: 20px;
        line-height: 24px;
        @include lg {
            font-size: 15px;
            line-height: 20px;
        }
        @include xl {
            font-size: 12px;
            line-height: 16px;
        }
    }

    a > span {
        display: none;
    }

    :global(.hover-image) > span,
    a:hover > span {
        display: block;
        pointer-events: none;

        display: flex;
        justify-content: center;
        align-items: center;

        position: absolute;
        z-index: 1000;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        @include sm {
            top: 50px;
        }

        @include md {
            top: 0;
        }

        @include xl {
            top: 50px;
            @include spacing(left, 20);
        }

        img {
            z-index: 100;
            display: block;
            height: auto;
            @include spacing(width, 56);
            @include sm {
                @include spacing(width, 28);
            }
            @include md {
                @include spacing(width, 32);
            }
            @include lg {
                @include spacing(width, 24);
            }
            @include xl {
                @include spacing(width, 19);
            }
        }
    }

    ul {
        list-style: none;
        padding: 0;
        margin: 0;
        li {
            margin-bottom: 24px;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}
