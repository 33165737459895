@import 'variables';

@mixin sm {
    @media screen and (min-width: $sm) {
        @content;
    }
}

@mixin md {
    @media screen and (min-width: $md) {
        @content;
    }
}

@mixin lg {
    @media screen and (min-width: $lg) {
        @content;
    }
}

@mixin xl {
    @media screen and (min-width: $xl) {
        @content;
    }
}

@mixin spacing($prop, $columns, $important: false) {
    $spacing: 100 / 80 * $columns;
    @if $important {
        #{$prop}: $spacing + 0% !important;
    } @else {
        #{$prop}: $spacing + 0%;
    }
}

@mixin spacingNew($prop, $columns: 1, $missingColumns: 0, $splits: 1) {
    $missingSpace: 100 / 80 * $missingColumns;
    $space: 100 / (80 - $missingColumns) * $columns * $splits;
    #{$prop}: $space + 0%;
}

@mixin headerSize {
    max-width: 100vw;
    height: $headerHeight-xs;
    @include spacing(padding-left, 2);
    @include spacing(padding-right, 2);

    @include sm {
        height: $headerHeight-sm;
        @include spacing(padding-left, 1);
        @include spacing(padding-right, 1);
    }
    @include md {
        height: $headerHeight-md;
    }
    @include lg {
        height: $headerHeight-lg;
    }
    @include xl {
        height: $headerHeight-xl;
    }
}

@mixin spacedForHeader {
    margin-top: $headerHeight-xs;
    @include sm {
        margin-top: $headerHeight-sm;
    }
    @include md {
        margin-top: $headerHeight-md;
    }
    @include lg {
        margin-top: $headerHeight-lg;
    }
    @include xl {
        margin-top: $headerHeight-xl;
    }
}
