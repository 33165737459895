@import '../../../assets/styles/variables';

.ModuleSingle {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;

    &-column {
        flex: 1 0 100%;
        width: 100%;
        height: 100%;

        display: flex;
        align-items: center;
        justify-content: center;
    }
}
