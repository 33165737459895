@import '../../../assets/styles/mixins';
@import '../../Image/mixins.scss';

.ModuleColumns {
    display: flex;
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;

    @include lg {
        flex-wrap: nowrap;
    }

    &-column {
        position: relative;

        flex: 1 0 100%;
        width: 100%;
        height: calc(50% - #{$headerHeight-xs / 2});

        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;

        &:first-of-type {
            margin-bottom: #{$headerHeight-xs / 2};
        }
        &:last-of-type {
            margin-top: #{$headerHeight-xs / 2};
        }

        @include sm {
            height: 50%;
            &:first-of-type,
            &:last-of-type {
                margin-bottom: 0;
                margin-top: 0;
            }
        }

        @include lg {
            flex: 1 0 50%;
            width: 50%;
            height: 100%;
        }
    }

    &-subcolumn {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        display: flex;
    }
}

.ImageSize {
    // PORTRAIT
    &-xl-portrait {
        @media screen and (max-width: #{$md - 1}) {
            @include portrait-covered-image;
        }
        @include md {
            @include spacing(width, 72);
        }
        @include lg {
            @include spacing(width, 38 * 2);
        }
        @include xl {
            @include portrait-covered-image;
        }
    }

    &-lg-portrait {
        @include spacing(width, 76);
        @include sm {
            @include spacing(width, 30);
        }
        @include md {
            @include spacing(width, 72);
        }
        @include lg {
            @include spacing(width, 38 * 2);
        }
        @include xl {
            @include spacing(width, 30 * 2);
        }
    }

    &-md-portrait {
        @include spacing(width, 56);
        @include sm {
            @include spacing(width, 24);
        }
        @include md {
            @include spacing(width, 48);
        }
        @include lg {
            @include spacing(width, 32 * 2);
        }
        @include xl {
            @include spacing(width, 24 * 2);
        }
    }

    &-sm-portrait {
        @include spacing(width, 40);
        @include sm {
            @include spacing(width, 20);
        }
        @include md {
            @include spacing(width, 32);
        }
        @include lg {
            @include spacing(width, 24 * 2);
        }
        @include xl {
            @include spacing(width, 16 * 2);
        }
    }

    // LANDSCAPE

    &-xl-landscape {
        @include spacing(width, 76);
        @include sm {
            @include spacing(width, 46);
        }
        @include md {
            @include spacing(width, 72);
        }
        @include lg {
            @include spacing(width, 40 * 2);
        }
        @include xl {
            @include spacing(width, 40 * 2);
        }
    }

    &-lg-landscape {
        @include spacing(width, 76);
        @include sm {
            @include spacing(width, 46);
        }
        @include md {
            @include spacing(width, 72);
        }
        @include lg {
            @include spacing(width, 40 * 2);
        }
        @include xl {
            @include spacing(width, 40 * 2);
        }
    }

    &-md-landscape {
        @include spacing(width, 68);
        @include sm {
            @include spacing(width, 36);
        }
        @include md {
            @include spacing(width, 56);
        }
        @include lg {
            @include spacing(width, 34 * 2);
        }
        @include xl {
            @include spacing(width, 34 * 2);
        }
    }

    &-sm-landscape {
        @include spacing(width, 56);
        @include sm {
            @include spacing(width, 30);
        }
        @include md {
            @include spacing(width, 32);
        }
        @include lg {
            @include spacing(width, 22 * 2);
        }
        @include xl {
            @include spacing(width, 22 * 2);
        }
    }
}

.ImageDoubleColumnSize {
    // Portrait
    &-xl-portrait,
    &-lg-portrait,
    &-md-portrait {
        @include spacing(width, 56);

        @include sm {
            @include spacing(width, 24);
        }
        @include md {
            @include spacing(width, 48);
        }
        @include lg {
            @include spacing(width, 26 * 2);
        }
        @include xl {
            @include spacing(width, 24 * 2);
        }
    }

    &-sm-portrait {
        @include spacing(width, 40);
        @include sm {
            @include spacing(width, 20);
        }
        @include md {
            @include spacing(width, 32);
        }
        @include lg {
            @include spacing(width, 17 * 2);
        }
        @include xl {
            @include spacing(width, 16 * 2);
        }
    }

    // Landscape
    &-xl-landscape,
    &-lg-landscape,
    &-md-landscape {
        @include spacing(width, 68);
        @include sm {
            @include spacing(width, 36);
        }
        @include md {
            @include spacing(width, 56);
        }
        @include lg {
            @include spacing(width, 34 * 2);
        }
        @include xl {
            @include spacing(width, 34 * 2);
        }
    }

    &-sm-landscape {
        @include spacing(width, 56);
        @include sm {
            @include spacing(width, 30);
        }
        @include md {
            @include spacing(width, 32);
        }
        @include lg {
            @include spacing(width, 22 * 2);
        }
        @include xl {
            @include spacing(width, 22 * 2);
        }
    }
}
