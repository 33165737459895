// COLORS
$black: #464543;
$white: #fffdf9;
$blue: #d3ecec;
$home-bg: #c8c4bd;
$gallery-bg: $home-bg;

// GRID SIZE
$sm: 768px;
$md: 768px;
$lg: 1024px;
$xl: 1366px;

// HEADER SIZES
$headerHeight-xs: 35px;
$headerHeight-sm: 35px;
$headerHeight-md: 72px;
$headerHeight-lg: 72px;
$headerHeight-xl: 60px;

// ACCORDION
$accordionHeaderHeight: 38px;
