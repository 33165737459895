@import '../../assets/styles/mixins';
@import '../../assets/styles/variables';

.Navigation {
    @include headerSize;

    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;

    display: flex;
    justify-items: space-between;
    align-items: center;

    border-bottom: 1px solid $black;

    opacity: 0;
    pointer-events: none;
    transition: opacity 800ms ease-in-out;

    @include md {
        align-items: flex-start;
    }

    & > a {
        flex: 1 0;

        color: $black;
        font-weight: 300;
        font-size: 15px;
        line-height: 17px;

        @include xl {
            font-size: 14px;
            line-height: 16px;
        }

        &:link,
        &:visited,
        &:hover,
        &:active {
            text-decoration: none;
        }

        @include md {
            margin-top: 20px;
        }
    }

    &--center {
        text-align: center;
    }

    &--right {
        text-align: right;
    }

    &-only-xs {
        @include sm {
            display: none;
        }
    }

    &-sm {
        display: none;
        @include sm {
            display: block;
        }
    }

    :global(.with-navigation) & {
        opacity: 1;
        pointer-events: auto;
        transition: opacity 800ms ease-in-out;
    }

    :global(.page-home) & {
        border-bottom: 0;
    }
}
